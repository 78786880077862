import { PROSPECTS } from '@/modules/Leads/constants/filter-constants';
import { useFetchLeadsQuery } from '@/services/leads/leadsApi';
import { DateSort, LeadDetailDrawerContextType } from '@/modules/Leads/types';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { resetFilter } from '@/store/leads.slice';
import { Stack } from '@chakra-ui/react';
import {
  useAddLeadBulkTagsMutation,
  useSetBulkLeadsInactiveMutation,
  useUpdateLeadStatusMutation,
} from '@/services/leads/leads.mutation';
import { ConfirmWithReason } from '@/utils/swal';
import { swalContent } from '@/types';
import TableLoader from '@/components/ui/Loaders/TableLoader';
import useToast from '@/hooks/useToast';

const Table = lazy(
  () => import('@/modules/Leads/components/table/prospects/New')
);
let content: swalContent = {
  title: 'Are you sure?',
  text: 'You are about to mark this lead as inactive, continue?',
  buttons: ['Cancel', true],
  icon: 'warning',
};
export default function NewProspect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { status, query, dateFilter, miles, tags, locations } = useSelector(
    (state: {
      leads: {
        status: number[];
        dateFilter: string;
        query: string;
        miles: { value: string; label: string };
        locations: Record<'value', number>[];
        tags: Array<{ value: number; label: string }>;
      };
    }) => state.leads
  );
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [sortOrder, setSortOrder] = useState<DateSort>('DESC');
  const [leadDetailRowId, setLeadDetailRowId] = useState<number | null>(null);
  const [reqSetBulkLeadsInActive] = useSetBulkLeadsInactiveMutation();
  const [reqChangeStatus] = useUpdateLeadStatusMutation();
  const [reqAddBulkTags] = useAddLeadBulkTagsMutation();
  const { data, isFetching } = useFetchLeadsQuery({
    unclaimed: false,
    is_prospect: 1,
    is_repeat_prospect: 0,
    types: [] as number[],
    hot: false,
    qualified: false,
    status,
    query,
    date_filter: dateFilter,
    page,
    take,
    orderBy: {
      column_name: 'created_at',
      sort_order: sortOrder,
    },
    zip_code: {
      distance: parseInt(miles?.value),
    },
    tags: tags?.map((tag) => tag.value),
    location: locations?.map((item) => ({ value: item?.value })),
  });
  const drawerContext = useMemo(
    () => ({
      rowId: leadDetailRowId ?? 0 + 1,
      totalLeads: data?.count,
      handlePrevData: handlePrevDrawerPagination,
      handleNextData: handleNextDrawerPagination,
      onClose: () => navigate('/leads/new-prospects'),
    }),
    [leadDetailRowId, data]
  );

  function handlePageChange(page: number) {
    setPage(page);
  }
  function handleEntriesChange(take: number) {
    setTake(take);
  }

  function handleDateSort() {
    setSortOrder((state) => (state === 'DESC' ? 'ASC' : 'DESC'));
  }
  async function handleNextDrawerPagination() {
    //  const res= await fetchLeads()
    if (take === (leadDetailRowId ?? 0 + 1) && (data?.count ?? 0) > take) {
      //reached end of current table pagination data
    } else {
      const nextLeadDetail = data?.data?.[leadDetailRowId ?? 0 + 1];
      if (nextLeadDetail) {
        setLeadDetailRowId((state) => (state ?? 0) + 1);
        navigate(`/leads/my-leads/${nextLeadDetail.id}/details`);
      }
    }
  }
  function handlePrevDrawerPagination() {
    if ((leadDetailRowId ?? 0) >= 0) {
      const prevLeadDetail = data?.data?.[leadDetailRowId ?? 0 - 1];
      setLeadDetailRowId((state) => (state ?? 0) - 1);
      navigate(`/leads/my-leads/${prevLeadDetail?.id}/details`);
    }
  }

  async function onBulkInActive(idList: number[]) {
    const submittedReason = await ConfirmWithReason(content);
    if (submittedReason) {
      await reqSetBulkLeadsInActive({
        data: { idList, inactive_reason: submittedReason },
      });
    }
  }
  async function onAddBulkTags(tags: Array<string>, ids: Array<number>) {
    try {
      const payload = await reqAddBulkTags({
        data: {
          tags: tags,
          idList: ids,
        },
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onChangeStatus(id: number, status: number) {
    const res = reqChangeStatus({ data: { status }, id }).unwrap();
    toast.promise(res, {
      error: { title: 'Something went wrong' },
      loading: { title: 'Changing status...' },
      success: { title: 'Successfully changed status!' },
    });
    return res;
  }

  useEffect(() => {
    const status = PROSPECTS[0].items
      .filter((stat) => stat.status)
      .map((item) => item.id);
    dispatch(resetFilter({ status }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      spacing={0}
      sx={{
        h: '100%',
        w: 'calc(100% - 320px)',
        bgColor: '#fff',
        borderRadius: '10px',
        p: '16px',
      }}
    >
      <Suspense
        fallback={
          <TableLoader header={['', '', '', '', '', '', '', '', '', '']} />
        }
      >
        <Table
          onDateSort={handleDateSort}
          onSubmitEmail={() => {}}
          rows={data?.data ?? []}
          isLoading={isFetching}
          onBulkInactive={onBulkInActive}
          onAddTags={onAddBulkTags}
          onChangeStatus={onChangeStatus}
          pagination={{
            onEntryChange: handleEntriesChange,
            onPageChange: handlePageChange,
            currentCount: data?.data?.length ?? 0,
            currentPage: page,
            targetCount: take,
            totalEntries: data?.count ?? 0,
            totalPages: data?.lastPage ?? 0,
          }}
        />
      </Suspense>

      {/* Render drawer */}
      <Outlet context={drawerContext as LeadDetailDrawerContextType} />
    </Stack>
  );
}
