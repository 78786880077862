import useTemplate from '@/components/app/Global/Email/hooks/useTemplate';
import { CAREER_PAGE_URL } from '@/constants/values';

// React and related hooks
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// API mutations and selectors
import { useListResumeCandidateMutation } from '@/store/candidateresume.slice';
import {
  useSubmitToClientMutation,
  useListCandidatesMutation,
} from '@/store/candidates.slice';
import { useViewClientMutation } from '@/store/client.slice';
import {
  useGetCandidateWorkStatusMutation,
  useGetEducationsMutation,
} from '@/store/constant.slice';
import {
  useListQuestionJobsMutation,
  useGetJobsMutation,
} from '@/store/jobs.slice';
import { getLead } from '@/store/leads.slice';
import {
  useRawListTemplateMutation,
  usePlaceHolderPairMutation,
  setPairTemplate,
} from '@/store/template.slice';
import { uploadList, cleanUpload } from '@/store/uploads.slice';
import { useUserListMutation } from '@/store/user.slice';

// Types
import { clientSelectType } from '@/types';

// Utilities
import replacePTags from '@/utils/replaceTags';
import replacePlaceholders from '@/utils/textPlaceholders';

// Validation
import * as Yup from 'yup';

interface CandidatePairingIntParam {
  candidate_full_name: string;
  candidate_first_name: string;
  candidate_email: string;
  candidate_current_position: string;
  candidate_current_company: string;
  candidate_mobile: string;
  candidate_linkedin_url: string;
  candidate_work_status: string;
  candidate_city: string;
  candidate_state: string;
  candidate_degree: string;
  candidate_total_years_of_exp: string;
  job_url: string;
  job_name_url: string;
  client_job_title: string;
  job_location: string;
  job_contact_name: string;
  job_contact_first_name: string;
  job_company_name: string;
  job_primary_recruiter_name: string;
  job_posting_title: string;
  email_signature: string;
  job_company_website: string;
  job_company_address: string;
  job_industry: string;
  job_posting_date: string;
  job_closing_date: string;
  candidate_status: string;
  candidate_source: string;
  candidate_recruiter_notes: string;
  job_requirements: string;
  application_process: string;
  hiring_manager_notes: string;
  internal_job_description: string;
  job_contact_title: string;
  job_ad_description: string;
}

interface useSubmitToClientServicesProps {
  candidate: any;
  isOpen: boolean;
  clientID: string;
  job_id: number;
  jobID: string;
  openAlert: () => void;
  onClose: () => void;
  callback: () => void;
}

const tabList = [
  { id: 0, title: 'Details' },
  { id: 1, title: 'Email' },
];
export const useSubmitToClientServices = ({
  candidate,
  isOpen,
  clientID,
  job_id,
  jobID,
  openAlert,
  onClose,
  callback,
}: useSubmitToClientServicesProps) => {
  const params = useParams();
  const user = useSelector((state: any) => state.auth);
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqEducation, resEducation] = useGetEducationsMutation();
  const [reqQuestion] = useListQuestionJobsMutation();
  const [reqSubmit, resSubmit] = useSubmitToClientMutation();
  const [reqCandidates] = useListCandidatesMutation();
  const { candidatePgBtn, candidateData, candidateDataJobId } = useSelector(
    (state: any) => state.candidates
  );

  const { defaultTemplate, isTemplatesFetching, templates, refetchTemplates } =
    useTemplate({ groupId: 2 });
  const [reqPair] = usePlaceHolderPairMutation();
  const [tokenLink, setTokenLink] = useState('');
  const [reqGetJob] = useGetJobsMutation();

  const { jobData } = useSelector((state: any) => state.jobs);
  const { userList } = useSelector((state: any) => state.user);

  const { uploading, uploaded } = useSelector((state: any) => state.uploads);
  const [quesetions] = useState([]);
  const [candidateDegreeList, setCandidateDegreeList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [contactOption, setContactOption] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);
  const [company, setCompany] = useState<clientSelectType | object>({});
  const [contactOptionsEmail, setContactOptionsEmail] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(true);

  const [reqGetClient] = useViewClientMutation();
  const [reqUsers] = useUserListMutation();
  const getLatestEduc = (candidate: any) => {
    try {
      if (candidate.educations) {
        if (candidate.educations.length > 0) {
          return {
            label: educations.filter(
              (val: any) => val.id === candidate.educations[0]?.degree_level_id
            )[0].name,
            value: candidate.educations[0]?.id,
          };
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const [formPairing, setFormPairing] = useState({
    candidate_degree:
      getLatestEduc(candidate)?.label ||
      getLatestEduc(candidateData)?.label ||
      '',
    total_year_of_experience: candidate?.total_year_of_experience || 0,
    total_months_of_experience: candidate?.total_months_of_experience || 0,
    work_status: candidate?.work_status?.status || '',
    candidate_linkedin_url: candidate?.linkedin_url || '',
    city: candidate?.city || '',
    state_province: candidate?.state_province || '',
    zip_code: candidate?.zip_code || '',
    job_contact_first_name: jobData?.lead?.first_name || '',
    job_contact_name:
      [jobData?.lead?.first_name, jobData?.lead?.last_name].join(' ') || '',
  });
  const templateList = useMemo(() => {
    return (
      templates?.map((template: any) => ({
        value: template.id,
        label: template.title,
        subject: template.subject,
        body: template.body,
        attachments: template.attachments,
      })) ?? []
    ).concat({
      value: null,
      label: 'Create New Template',
      subject: '',
      body: '',
      attachments: null,
    });
  }, [templates]);

  const { candidate_work_status, educations } = useSelector(
    (state: any) => state.constants
  );

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const { selected_contact } = useSelector((state: any) => state.leads);

  const ccBc = (emails: any) => {
    let options: any = [];
    try {
      emails.filter(Boolean).map((email: any) => {
        let label = '';

        if (contactOption?.length > 0) {
          contactOption.map(async (item: any) => {
            if (item.value === email) {
              label = item.label;
            }
          });
        }
        if (label === '') {
          Object.values(userList).map((user: any) => {
            if (user.email === email) {
              label = String(
                [user.first_name, user.last_name].filter(Boolean).join(' ')
              );
            }
            if (user.email === email) {
              label = String(
                [user.first_name, user.last_name].filter(Boolean).join(' ')
              );
            }
          });
        }
        options.push({
          label: label,
          value: email,
        });
      });
    } catch (e) {
      console.warn('e', e);
    }

    return options;
  };

  const setCc = (jobData: any) => {
    if (jobData?.bcc?.cc) {
      return ccBc(jobData?.bcc?.cc);
    }
    return [];
  };

  const setBcc = (jobData: any) => {
    if (jobData?.bcc?.bcc) {
      return ccBc(jobData?.bcc?.bcc);
    }
    return [];
  };

  const [bccValue, setBccValue] = useState(setBcc(jobData));
  const [ccValue, setCcValue] = useState(setCc(jobData));

  const { placehoderPair, defaultTemplateList, templateRawList } = useSelector(
    (state: any) => state.template
  );
  const [placehoder, setPlaceHoders] = useState(placehoderPair);

  const dispatch = useDispatch();

  const populateTemplate = (template: any) => {
    try {
      if (template) {
        return {
          value: template?.id,
          label: template?.title,
          subject: template?.subject,
          body: template?.body,
          ...(template?.attachments && { attachments: template.attachments }),
        };
      } else {
        return null as any;
      }
    } catch (e) {
      return null;
    }
  };

  const populateUsers = () => {
    let emails = [] as any;

    Object.values(userList).map((item: any) => {
      emails.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });
    return emails;
  };

  const pairPH = (holder: string, value: string) => {
    setFormPairing((prevPairing) => ({
      ...prevPairing,
      [holder]: value,
    }));
  };

  const pairPHMultiple = (updates: Record<string, string>) => {
    setFormPairing((prevPairing) => ({
      ...prevPairing,
      ...updates,
    }));
  };

  const loadConstant = () => {
    if (clientID || jobData.client?.id) {
      const client_id = clientID || jobData.client?.id;
      reqGetClient({ id: client_id });
    }
    reqUsers({});
  };

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const [initialValues, setInitialValue] = useState<any>({
    job_title: candidate?.latest_job_title,
    company: candidate?.latest_employer,
    city: candidate?.city,
    state_province: candidate?.state_province,
    zip_code: candidate?.zip_code,
    education: getLatestEduc(candidate) ||
      getLatestEduc(candidateData) || {
        label: 'None',
        value: -1,
      },
    total_year_of_experience: candidate?.total_year_of_experience,
    total_months_of_experience: candidate?.total_months_of_experience,
    work_status: candidate?.work_status_id || candidate?.work_status?.id,
    resume: '',
    linked_in_url: candidate?.linkedin_url || '',
    isSubmitQA: 2,
    to: {},
    cc: [] as any,
    bcc: [] as any,
    subject: defaultTemplate?.subject || '',
    notes: defaultTemplate?.body || '',
    attachment: defaultTemplate?.attachments || ([] as any),
    questions: [],
    lead_id: 1,
    candidate_id: candidate?.id,
    body: defaultTemplate?.body || '',
    template: defaultTemplate,
  });

  const [tabIndex, setTabIndex] = useState(tabList[0]);

  const onSubmit = (formValue: any) => {
    const data = { ...formValue };
    delete data.template;
    let dataAtt: any[] = [];

    data['attachment'] = uploaded;

    if (attachments?.length > 0) {
      attachments.map((att: any) => {
        dataAtt.push({
          file_name: att?.file_name,
          file_size: att?.file_size,
          file_type: att?.file_type,
          location: att?.location,
          original_file_name: att?.original_file_name,
          bucket: att?.bucket || 'talently-outreach',
        });
      });
      data['templat_att'] = attachments;
    }

    setTokenLink('');
    let cc: any = [];
    if (ccValue.length > 0) {
      ccValue.map((item: any) => {
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (bccValue.length > 0) {
      bccValue.map((item: any) => {
        bcc.push(item.value);
      });
    }
    data.body = placholders(replacePTags(data.body));

    if (data.body === '') {
      setErrorMsg('Email body is required');
      openAlert();
    } else {
      data['to'] = data['to'].value;
      data['subject'] = simplePlaceholder(data?.subject || '');

      const payload = {
        ...data,
        cc: cc,
        bcc: bcc,
        candidate_id: candidateData.id,
      };

      reqSubmit({
        data: payload,
        id: params?.jobId || job_id || candidateData?.jobs?.[0]?.job_id,
      });
    }
  };

  const candidatePairing = async (param: CandidatePairingIntParam) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      candidate_city: param.candidate_city,
      candidate_state: param.candidate_state,
      candidate_degree: param.candidate_degree,
      candidate_total_years_of_exp: param.candidate_total_years_of_exp,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      client_job_title: param.client_job_title,
      job_location: param.job_location,
      internal_job_description: param.internal_job_description,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_contact_title: param.job_contact_title,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
      job_ad_description: param.job_ad_description,
      job_posting_title: param.job_posting_title,
      email_signature: param.email_signature,
      job_company_website: param.job_company_website,
      job_company_address: param.job_company_address,
      job_industry: param.job_industry,
      job_posting_date: param.job_posting_date,
      job_closing_date: param.job_closing_date,
      candidate_status: param.candidate_status,
      candidate_source: param.candidate_source,
      candidate_recruiter_notes: param.candidate_recruiter_notes,
      job_requirements: param.job_requirements,
      application_process: param.application_process,
      hiring_manager_notes: param.hiring_manager_notes,
    };
    dispatch(setPairTemplate({ placehoderPair: newpair }));

    // await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };
  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  const simplePlaceholder = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const removeAtt = (key: any) => {
    setAttachments([
      ...attachments.slice(0, key),
      ...attachments.slice(key + 1, attachments.length),
    ]);
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
      template: Yup.object().shape({
        label: Yup.string().required('Template is required.'),
        value: Yup.number().required('Template is required.'),
      }),
    })
  );

  const [resumeUrl, setResumeUrl] = useState('');
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [redactVersion, setRedactedVersion] = useState(null);

  const [fetchResume, setFetchResume] = useState(false);
  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [reqAttachment] = useListResumeCandidateMutation();
  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUsers()];
      setCcOption(options);
    } else {
      setCcOption(contactOptionsEmail);
    }
  };

  const onBccInputChange = (e: any) => {
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUsers()];
      setBccOption(options);
    } else {
      setBccOption(contactOptionsEmail);
    }
  };
  const templateCallback = (setFieldValue: any) => {
    refetchTemplates().then((res: any) => {
      const data = res.data.data;

      const highestIdIndex = data.reduce(
        (maxIndex: any, item: any, index: any, array: any) =>
          item.id > array[maxIndex].id ? index : maxIndex,
        0
      );

      const temp = data[highestIdIndex];

      setFieldValue('template', temp);
      setFieldValue('body', temp.body);
      setFieldValue('subject', temp.subject);
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFormPairing((prev) => ({
        ...prev,
        candidate_degree:
          getLatestEduc(candidate)?.label ||
          getLatestEduc(candidateData)?.label ||
          '',
        total_year_of_experience: candidate?.total_year_of_experience || 0,
        total_months_of_experience: candidate?.total_months_of_experience || 0,
        work_status: candidate?.work_status?.status || '',
        candidate_linkedin_url: candidate?.linkedin_url || '',
        city: candidate?.city || '',
        state_province: candidate?.state_province || '',
        zip_code: candidate?.zip_code || '',
        job_contact_first_name: jobData?.lead?.first_name || '',
        job_contact_name:
          [jobData?.lead?.first_name, jobData?.lead?.last_name].join(' ') || '',
      }));
    }
  }, [isOpen]);
  // useEffect(() => {
  //   if (resGetClient.isSuccess) {
  //     const company = resGetClient.data.data;
  //     let optionsEmails: any = [];
  //     if (company !== undefined) {
  //       setCompany({
  //         label: company.name,
  //         value: company.id,
  //       });
  //       if (company.leads?.length > 0) {
  //         company.leads.map(async (item: any) => {
  //           optionsEmails.push({
  //             label: `${[item.first_name, item.last_name]
  //               .filter(Boolean)
  //               .join(' ')} <${item.primary_email}>`,
  //             first_name: item.first_name,
  //             name: [item.first_name, item.last_name].filter(Boolean).join(' '),
  //             value: item.primary_email,
  //           });
  //         });
  //       }
  //     }

  //     setContactOption(optionsEmails);
  //     setCcOption(optionsEmails);
  //     setBccOption(optionsEmails);
  //     setContactOptionsEmail(optionsEmails);
  //   }
  // }, [resGetClient.isSuccess, selected_contact]);

  useEffect(() => {
    if (isOpen) {
      const educ: any = getLatestEduc(candidate);
      let options: any = [];
      educations?.map((item: any) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });

      setCandidateDegreeList(options);
    }
  }, [isOpen]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        setPairTemplate({
          placehoderPair: { ...placehoderPair, ...formPairing },
        })
      );
    }, 300); // Waits 300ms before dispatching

    return () => clearTimeout(timeout); // Cleanup function
  }, [formPairing]);

  useEffect(() => {
    setInitialValue((initialValues: any) => ({
      ...initialValues,
      body: defaultTemplate?.body,
      template: defaultTemplate,
      subject: defaultTemplate?.subject || '',
      notes: defaultTemplate?.body || '',
      attachment: defaultTemplate?.attachments || ([] as any),
    }));
  }, [defaultTemplate]);
  useEffect(() => {
    //populate cc & bcc
    if ((params?.jobId || job_id || jobID) && isOpen) {
      reqGetJob({ id: params?.jobId || job_id || jobID })
        .unwrap()
        .then((data) => {
          const jobData = data?.data;
          if (jobData) {
            setCompany({
              label: jobData.data.client.name,
              value: jobData.data.client.id,
            });
            const leads = jobData?.data?.leads
              ?.map((lead: any) => ({
                label: `${[lead?.first_name, lead?.last_name]
                  .filter(Boolean)
                  .join(' ')} <${lead?.primary_email}>`,
                first_name: lead?.first_name,
                name: [lead?.first_name, lead?.last_name]
                  .filter(Boolean)
                  .join(' '),
                value: lead?.primary_email,
              }))
              .concat({
                label: `${[user?.first_name, user?.last_name]
                  .filter(Boolean)
                  .join(' ')} <${user?.email}>`,
                first_name: user?.first_name,
                name: [user?.first_name, user?.last_name]
                  .filter(Boolean)
                  .join(' '),
                value: user?.email,
              });
            let cc: any = leads.filter((leada: any) =>
              jobData?.bcc?.cc?.includes(leada.value)
            );
            let bcc: any = leads.filter((leada: any) =>
              jobData?.bcc?.bcc?.includes(leada.value)
            );

            setContactOption(leads);
            setCcOption(leads);
            setBccOption(leads);
            setContactOptionsEmail(leads);
            setInitialValue((initialValues: any) => ({
              ...initialValues,
              to: {
                label: [
                  jobData?.data?.lead?.first_name,
                  jobData?.data?.lead?.last_name,
                ]
                  .filter(Boolean)
                  .join(' '),
                value: jobData?.data?.lead?.primary_email,
              },
              cc: cc,
              bcc: bcc,
              questions: quesetions,
              lead_id: jobData?.data?.lead_id,
            }));
          }
        });
    }
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      if (candidateData) {
        const candidate_full_name = candidateData?.data
          ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
              .filter(Boolean)
              .join(', ')
          : [candidateData?.first_name, candidateData?.last_name]
              .filter(Boolean)
              .join(', ') || '';
        const candidate_first_name =
          candidateData?.data?.first_name ||
          candidateData?.first_name ||
          placehoderPair?.first_name ||
          '';
        const candidate_email =
          candidateData?.data?.primary_email ||
          candidateData?.primary_email ||
          placehoderPair?.candidate_email ||
          '';
        const candidate_current_position =
          candidateData?.data?.latest_job_title ||
          candidateData?.latest_job_title ||
          placehoderPair?.candidate_current_position ||
          '';
        const candidate_current_company =
          candidateData?.data?.latest_employer ||
          candidateData?.latest_employer ||
          placehoderPair?.candidate_current_company ||
          '';
        const candidate_mobile =
          getPrimaryMobile(candidateData?.data?.contact) ||
          placehoderPair?.candidate_mobile ||
          '';
        const candidate_linkedin_url =
          candidateData?.linkedin_url ||
          placehoderPair?.candidate_linkedin_url ||
          '';
        const candidate_work_status =
          candidateData?.work_status?.status ||
          placehoderPair?.candidate_work_status ||
          '';
        const candidate_city =
          candidateData?.city || placehoderPair?.candidate_city || '';
        const candidate_state =
          candidateData?.state_province ||
          placehoderPair?.candidate_state ||
          '';
        const candidate_degree =
          getLatestEduc(candidateData)?.label ||
          placehoderPair?.candidate_degree ||
          '';
        const candidate_total_years_of_exp =
          candidateData?.total_year_of_experience ||
          placehoderPair?.candidate_total_years_of_exp ||
          '';
        let job_url = '';
        let job_name_url = '';
        let job_title = '';
        let job_location = '';
        let job_contact_name = placehoderPair?.job_contact_name || '';
        let job_contact_first_name =
          placehoderPair?.job_contact_first_name || '';
        let job_company_name = '';
        let job_primary_recruiter_name = '';
        if (job_id || candidateData?.jobs?.[0]?.job_id) {
          job_title = jobData?.title || placehoderPair?.job_title || '';
          job_location =
            [
              jobData?.data?.location?.city,
              jobData?.data?.location?.state,
              jobData?.data?.location?.country?.country,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_location ||
            '';
          job_contact_name =
            [jobData?.lead?.first_name, jobData?.lead?.last_name]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_contact_name ||
            '';
          job_contact_first_name =
            jobData?.lead?.first_name ||
            placehoderPair?.job_contact_first_name ||
            '';
          job_company_name =
            jobData?.client?.name || placehoderPair?.job_company_name || '';
          job_primary_recruiter_name =
            [
              jobData?.primary?.recruiter?.first_name,
              jobData?.primary?.recruiter?.last_name,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_primary_recruiter_name ||
            '';
          if (jobData?.jobAds?.length > 0) {
            const ads = jobData?.jobAds[0];

            if (ads) {
              const url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
              job_url = `<a href="${url}" target="_blank">${url}</a>`;
              job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
            }
          }
        }

        const candidateParam = {
          candidate_full_name,
          candidate_first_name,
          candidate_email,
          candidate_current_position,
          candidate_current_company,
          candidate_mobile,
          candidate_linkedin_url,
          candidate_work_status,
          candidate_city,
          candidate_state,
          candidate_degree,
          candidate_total_years_of_exp,
          job_url,
          job_name_url,
          client_job_title: job_title,
          job_location,
          job_contact_name,
          job_contact_first_name,
          job_company_name,
          job_primary_recruiter_name,
        } as CandidatePairingIntParam;

        candidatePairing(candidateParam);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);
  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );

    const redacted_filtered = resumes.filter(
      (obj: { sub_file_type: string }) => obj.sub_file_type === 'redacted'
    );
    const redacted_sorted = redacted_filtered.sort((a: any, b: any) => {
      // Ensure 'created_at' is properly converted to Date objects or timestamps
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();

      return dateB - dateA; // Sort by latest first
    });
    const redacted_version = redacted_sorted[0];

    if (redacted_version && Object.keys(redacted_version).length > 0) {
      setInitialValue((initialValues: any) => ({
        ...initialValues,
        redacted_resume: true,
        primary: false,
      }));
    } else {
      setInitialValue((initialValues: any) => ({
        ...initialValues,
        redacted_resume: false,
        primary: true,
      }));
    }

    setRedactedVersion(redacted_version);
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
    }
  }, [resumes, fetchResume]);

  useEffect(() => {
    if (isOpen) {
      loadConstant();
    }
  }, [isOpen, selected_contact]);

  useEffect(() => {
    reqCanWorkStat({});
    reqEducation({});
    const data = {
      candidate_id: candidate.id,
      job_id: params?.jobId || job_id || jobID || candidateDataJobId,
    };
    reqPair(data).then((data: any) => {
      setFormPairing((pair: any) => {
        const newPair = { ...data?.data?.data, ...pair };
        dispatch(
          setPairTemplate({
            placehoderPair: newPair,
          })
        );

        return newPair;
      });
    });
    reqQuestion({ id: params?.jobId || job_id || jobID });
    setTokenLink('');
  }, [params.jobId, job_id, jobID]);

  useEffect(() => {
    if (resSubmit.isError) {
      const error: any = resSubmit.error;
      try {
        const message =
          error?.data?.error?.message ||
          error?.data?.data?.message ||
          'Unkown error';

        if (error?.data?.data?.type == 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.error('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resSubmit.isError]);

  useEffect(() => {
    if (resSubmit.isSuccess) {
      onClose();
      callback();
      params?.jobId && reqGetJob({ id: params?.jobId });
      if (!params?.jobsTabNav) {
        reqCandidates({ data: candidatePgBtn });
      }

      dispatch(uploadList({ prefix: 'Attachment' }));
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      dispatch(getLead({ selected_contact: [] }));

      setTokenLink('');
    }
  }, [resSubmit.isSuccess]);
  useEffect(() => {
    const api = async () => {
      await reqAttachment({ id: candidate.id });
    };
    api();
  }, [candidate.id, fetchResume]);

  return {
    selectedVersion,
    setResumeUrl,
    jobData,
    errMsg,
    tokenLink,
    initialValues,
    onSubmit,
    validationSchema,
    tabIndex,
    pairPH,
    candidate_work_status,
    isLoadingEduc: resEducation.isLoading,
    candidateDegreeList,
    company,
    contactOption,
    onCcInputChange,
    setCcValue,
    onBccInputChange,
    setBccValue,
    previewOpen,
    setPreviewOpen,
    placholders,
    attachments,
    setAttachments,
    populateTemplate,
    template: templateList,
    setTabIndex,
    isLoadingSubmit: resSubmit.isLoading,
    uploading,
    ccOption,
    bccOption,
    tabList,
    templateCallback,
    redactVersion,
    setInitialValue,
    setFetchResume,
    fetchResume,
    pairPHMultiple,
    isTemplatesFetching,
  };
};
