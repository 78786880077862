import {
  Flex,
  Box,
  Link,
  Image,
  useDisclosure,
  CircularProgress,
  Text,
} from '@chakra-ui/react';
import { BiMobile } from 'react-icons/bi';
import telephoneImg from '@/assets/images/leads/telephone.svg';
import { lazy, Suspense } from 'react';
import { BiMailSend } from 'react-icons/bi';
import ModalLoader from '@/components/ui/Loaders/ModalLoader';

const EmailModal = lazy(() => import('@/components/app/Global/Email/Lead'));
function ContactIcon({
  icon,
  onClick,
}: {
  icon: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Box
      sx={{
        bgColor: '#E7EDF4',
        width: '24px',
        height: '24px',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#718096',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {icon}
    </Box>
  );
}
export default function ContactInformation({
  personal_phone,
  work_phone,
  primary_email,
  id,
  isEnrich,
}: {
  personal_phone: string;
  work_phone: string;
  primary_email: string;
  id: number;
  isEnrich?: boolean;
}) {
  return (
    <Flex columnGap={'8px'} w="100%">
      {primary_email ? <EmailModalBtn email={primary_email} leadId={id} /> : ''}
      {personal_phone ? (
        <Link
          href={`tel:${personal_phone}`}
          fontSize="14px"
          fontWeight={400}
          isTruncated
        >
          <ContactIcon icon={<BiMobile />} />
        </Link>
      ) : (
        ''
      )}
      {work_phone ? (
        <Link
          href={`tel:${work_phone}`}
          fontSize="14px"
          fontWeight={400}
          isTruncated
        >
          <ContactIcon icon={<Image src={telephoneImg} alt="work-phone" />} />
        </Link>
      ) : (
        ''
      )}
      {isEnrich ? <Enrich /> : null}
    </Flex>
  );
}
function EmailModalBtn({ email, leadId }: { email: string; leadId: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ContactIcon icon={<BiMailSend />} onClick={onOpen} />
      {isOpen ? (
        <Suspense fallback={<ModalLoader />}>
          <EmailModal
            isOpen={isOpen}
            onClose={onClose}
            email={email}
            id={leadId}
            allowedCategory={['Client', 'Contact', 'Signatures']}
          />
        </Suspense>
      ) : null}
    </>
  );
}

function Enrich() {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      gap={'4px'}
      p="4px"
      borderRadius={'3px'}
      border={'1px solid #F9ECDA'}
      h="fit-content"
    >
      <CircularProgress
        value={60}
        color="#D68309"
        size="12px"
        thickness="15px"
      />
      <Text color="#D68309" fontSize={'10px'} lineHeight={'normal'}>
        Enrich
      </Text>
    </Flex>
  );
}
