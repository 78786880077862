import {
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
} from '@chakra-ui/react';
import FAIcon from '@/components/lib/FAIcon';
import Pagination from '@/Library/Pagination';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import toUrl from '@/utils/toUrl';
import {
  ExistingClientResponseType,
  NewProspectAPIResponseType,
} from '../../../types';
import BulkAction from '../components/BulkAction';
import RowGroupedTable, {
  ExpandedState,
} from '@/components/ui/Table/RowGroupedTable';
import Tags from '../components/Tags';
import ContactInformation from '../components/ContactInformation';
import BDStatus, { BDStatusItem } from './components/BDStatus';
import LastActivityChip from '../../chip/LastActivityChip';
import TimeChip from '../../chip/TimeChip';
import { ColumnDef } from '@tanstack/react-table';
import ProspectDetails from './components/ProspectDetails';

interface TableProps {
  rows: ExistingClientResponseType[];
  onDateSort: () => void;
  onSubmitEmail: () => void;
  isLoading?: boolean;
  onAddTags: (tags: Array<string>, ids: Array<number>) => Promise<any>;
  onBulkInactive: (ids: Array<number>) => Promise<any>;
  onChangeStatus: (id: number, status: number) => Promise<any>;
  pagination: {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    totalEntries: number;
    onEntryChange: (entries: number) => void;
    currentCount: number;
    targetCount: number;
  };
}

interface TableData {
  id: number;
  first_name: string;
  last_name: string;
  linked_in_url: string;
  note_count: number;
  title: string;
  client_id: number;
  client: { name: string; id: number };
  personal_phone: string;
  primary_email: string;
  work_phone: string;
  client_job_link: string;
  client_job_title: string;
  leadStatus: { lead_status: string; id: number };
  created_at: Date;
  last_activity: {
    call: Date;
    sms: Date;
    video: Date;
    latest: Date;
  };
  tags: string[];
  enriched: boolean;
}
interface TableRows extends TableData {
  subRows: TableRows[];
}
export default function ExistingClientProspects({
  rows,
  onDateSort,
  onSubmitEmail,
  onBulkInactive,
  onChangeStatus,
  onAddTags,
  isLoading,
  pagination: {
    totalPages,
    currentPage,
    onPageChange,
    totalEntries,
    onEntryChange,
    currentCount,
    targetCount,
  },
}: TableProps) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const columns: ColumnDef<TableRows, unknown>[] = useMemo(() => {
    function handleClick(id: number) {
      navigate(`/leads/existing-client-prospects/${id}/details`);
    }
    return [
      {
        id: 'select',
        size: 41,
        header: ({ table }) => {
          return (
            <Checkbox
              isChecked={table.getIsAllPageRowsSelected()}
              isIndeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              aria-label="Select all"
              name="all"
              id="select-all"
            />
          );
        },

        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            aria-label={`Select row ${row.id}`}
            id={String(row.id)}
            name={String(row.id)}
          />
        ),
        // meta: { sticky: true, left: 0 },
      },
      {
        accessorKey: 'prospect',
        header: 'Prospect',
        size: 300,
        cell: ({ row }) => {
          const data = row.original;
          return (
            <ProspectDetails
              first_name={data?.first_name ?? ''}
              last_name={data.last_name}
              id={data.id}
              onDetailOpen={() => handleClick(data.id)}
              linked_in_url={data.linked_in_url}
              isNotesExists={data.note_count > 0}
            />
          );
        },
      },
      {
        accessorKey: 'job_title',
        header: 'Job Title',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Text isTruncated fontSize={'md'}>
              {rowData.title}
            </Text>
          );
        },
      },
      {
        accessorKey: 'company',
        header: 'Company',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Box isTruncated w="100%">
              <Link href={`/clients/my-clients/${rowData.client_id}/overview`}>
                <Text isTruncated fontSize={'md'} cursor={'pointer'}>
                  {rowData.client?.name ?? ''}
                </Text>
              </Link>
            </Box>
          );
        },
      },
      {
        accessorKey: 'contact',
        header: 'Contact Information',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <ContactInformation
              id={rowData.id}
              personal_phone={rowData.personal_phone}
              primary_email={rowData.primary_email}
              work_phone={rowData.work_phone}
              isEnrich={rowData.enriched}
            />
          );
        },
      },

      {
        accessorKey: 'client_job_link',
        header: 'Client Job Link',
        size: 250,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <Box isTruncated width="100%">
              {!!rowData.client_job_link && (
                <Link
                  href={toUrl(rowData?.client_job_link)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {rowData?.client_job_title || toUrl(rowData?.client_job_link)}
                </Link>
              )}
            </Box>
          );
        },
      },

      {
        accessorKey: 'status',
        header: 'Status',
        size: 250,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <BDStatus
              onChange={(option) => onChangeStatus(rowData.id, option.value)}
              value={rowData?.leadStatus?.id as 6 | 7 | 8 | 9 | 10 | 11}
            />
          );
        },
      },
      {
        accessorKey: 'last_activity',
        header: 'Last Activity',
        size: 120,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <LastActivityChip
              latest={rowData.last_activity.latest}
              call={rowData.last_activity.call}
              sms={rowData.last_activity.sms}
              video={rowData.last_activity.video}
            />
          );
        },
      },
      {
        accessorKey: 'bd_status',
        header: 'BD Status',
        size: 150,
        cell: ({ row }) => {
          const rowData = row.original;
          return (
            <BDStatusItem
              statusId={rowData?.leadStatus?.id as 6 | 7 | 8 | 9 | 10 | 11}
            />
          );
        },
      },

      {
        accessorKey: 'created_on',
        header: 'Created On',
        size: 120,
        cell: ({ getValue }) => {
          return <TimeChip text={getValue() as Date} />;
        },
      },
      {
        accessorKey: 'created_at',
        header: () => (
          <Flex gap="10px" justifyContent="start" alignItems="center">
            Created Date
            <Flex onClick={onDateSort} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        ),
        size: 120,
        cell: ({ getValue }) => {
          return (
            <Text fontSize={'md'}>
              {moment.utc(getValue() as Date).format('MM/DD/YYYY')}
            </Text>
          );
        },
      },
      {
        accessorKey: 'tags',
        header: 'Tags',
        size: 200,
        cell: ({ row }) => {
          const rowData = row.original;
          return <Tags tags={rowData.tags} />;
        },
      },
    ];
  }, []);
  const data = useMemo(() => {
    return (
      rows?.map((client) => ({
        id: client.client_id,
        client_name: client.client_name,
        first_name: '',
        last_name: '',
        linked_in_url: '',
        note_count: 0,
        title: '',
        client_id: client.client_id,
        client: { name: client.client_name, id: client.client_id },
        personal_phone: '',
        primary_email: '',
        work_phone: '',
        client_job_link: '',
        client_job_title: '',
        leadStatus: { lead_status: '', id: 0 },
        created_at: new Date(),
        last_activity: {
          call: new Date(),
          sms: new Date(),
          video: new Date(),
          latest: new Date(),
        },
        tags: [],
        enriched: false,
        subRows: client.leads.map((lead) => ({
          ...lead,
          subRows: [],
        })),
      })) ?? []
    );
  }, [rows]);

  const [selectedRows, setSelectedRows] = useState<
    { id: number; email: string }[]
  >([]);
  const [leadDetail, setLeadDetail] = useState<{
    leads: NewProspectAPIResponseType[];
    rowId: null | number;
  }>({ leads: [], rowId: null });

  const handlePrevData = useCallback(() => {
    const leadID = leadDetail.leads[leadDetail?.rowId! - 1 - 1].id;
    setLeadDetail((state) => ({ ...state, rowId: state?.rowId! - 1 }));
    navigate(`/leads/prospects/existing-client-prospects/${leadID}/details`);
  }, [leadDetail.leads, leadDetail.rowId, navigate]);

  const handleNextData = useCallback(() => {
    const leadID = leadDetail.leads[leadDetail.rowId! - 1 + 1].id;
    setLeadDetail((state) => ({ ...state, rowId: state.rowId! + 1 }));
    navigate(`/leads/existing-client-prospects/${leadID}/details`);
  }, [leadDetail.leads, leadDetail.rowId, navigate]);

  const onRowSelection = useCallback((rows: TableData[]) => {
    setSelectedRows(
      rows.map((row) => ({ id: row.id, email: row.primary_email }))
    );
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" pb={'16px'}>
        <Flex gap={4}>
          {selectedRows.length > 0 ? (
            <BulkAction
              leads={selectedRows}
              onAddTags={(tags) =>
                onAddTags(
                  tags,
                  selectedRows.map((data) => data.id)
                )
              }
              onMarkInActive={() => {
                onBulkInactive(selectedRows.map((data) => data.id));
              }}
            />
          ) : (
            <Box mb={6}></Box>
          )}
        </Flex>
      </Flex>
      <Grid templateRows={'repeat(10,1fr)'} h="calc(100% - 50px)">
        <GridItem rowStart={1} rowEnd={11} w="100%" overflow="hidden">
          <RowGroupedTable
            columns={columns}
            data={data}
            enableMultiRowSelection={true}
            expanded={expanded}
            setExpanded={setExpanded}
            onRowSelectionChange={onRowSelection}
            ExpanderComponent={ExpanderComponent}
            isAllExpanded={true}
            isAllowCheckOnCollapsed={true}
            isLoading={isLoading}
          />
        </GridItem>
        <GridItem rowStart={11} rowEnd={12} zIndex={20}>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalEntries={totalEntries}
            onEntryChange={onEntryChange}
            currentCount={currentCount}
            targetCount={targetCount}
          />
        </GridItem>
      </Grid>

      <Outlet
        context={{
          onPaginationNext: handleNextData,
          onPaginationPrev: handlePrevData,
          rowId: leadDetail?.rowId,
          totalLeads: leadDetail?.leads?.length,
        }}
      />
    </>
  );
}

function ExpanderComponent({ data }: { data: TableData }) {
  return (
    <Link
      fontSize="12px"
      href={`/clients/my-clients/${data.client_id}/overview`}
      target="_blank"
      rel="noreferrer"
    >
      {data.client.name}
    </Link>
  );
}
